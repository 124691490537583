@font-face {
    font-family: 'Helvetica World';
    src: local('Helvetica World Regular'), local('Helvetica-World-Regular'),
        url('HelveticaWorld-Normal/HelveticaWorld-Regular.woff2') format('woff2'),
        url('HelveticaWorld-Normal/HelveticaWorld-Regular.woff') format('woff'),
        url('HelveticaWorld-Normal/HelveticaWorld-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

@font-face {
    font-family: 'Helvetica World';
    src: local('Helvetica World Italic'), local('Helvetica-World-Italic'),
    url('HelveticaWorld-Italic/HelveticaWorld-Italic.woff2') format('woff2'),
    url('HelveticaWorld-Italic/HelveticaWorld-Italic.woff') format('woff'),
    url('HelveticaWorld-Italic/HelveticaWorld-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica World';
    src: local('Helvetica World Bold Italic'), local('Helvetica-World-Bold-Italic'),
    url('HelveticaWorld-BoldItalic/HelveticaWorld-BoldItalic.woff2') format('woff2'),
    url('HelveticaWorld-BoldItalic/HelveticaWorld-BoldItalic.woff') format('woff'),
    url('HelveticaWorld-BoldItalic/HelveticaWorld-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica World';
    src: local('Helvetica World Bold'), local('Helvetica-World-Bold'),
    url('HelveticaWorld-Bold/HelveticaWorld-Bold.woff2') format('woff2'),
    url('HelveticaWorld-Bold/HelveticaWorld-Bold.woff') format('woff'),
    url('HelveticaWorld-Bold/HelveticaWorld-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

body{
    font-family: 'Helvetica World';
}